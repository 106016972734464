import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import {StaticQuery, graphql, Link} from 'gatsby'
import Img from "gatsby-image"

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 1em;
`
const RoofImage = styled(Img)`
  width: 100%
`
const Copy = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem;
  justify-content: center;
`
const Left = styled.div`
  order: 1;
  margin: 0rem 2rem;
`
const Right = styled.div`
  order: 2;
  margin: 0rem 2rem;
`

const Bottom = styled.div`
  padding: 1.5rem;
`

const RepairPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          image: file(relativePath: { eq: "damagedshingles.png" }) {
            childImageSharp {
              fluid(quality: 20, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Repair" />
          
          <ImageContainer>
            <RoofImage fluid={data.image.childImageSharp.fluid}></RoofImage>
          </ImageContainer>
          <Copy>
            If it's on a roof and needs repairing, we can fix it. Here are a few services offered:
          </Copy>
          <Copy>
            <Left>
            <li>Leaky Roofs</li> 
            <li>Shingle Repair</li> 
            <li>Storm Damage</li> 
            <li>Skylights</li>
            </Left>
            <Right>
                <li>Aging Roofs</li>
              <li>Damaged Venting</li>
              <li>Leaky Pipe Collars</li>
              <li>And More</li>            
            </Right>
            <br/>
            <br/>
          </Copy>
          <Bottom>
            To request a free quote, fill out the form to the right or <Link to='/contactus'>contact us</Link> to have one of our staff members call or email you at your convenience. 
          </Bottom>


        </Layout>
      )}
    />
  )
}

export default RepairPage
